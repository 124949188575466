<template>
    <div>
        <CBadge v-if="statusColor(row.value)"
                :style="'background-color:'+statusColor(row.value)">{{ row.value }}
        </CBadge>
        <CBadge v-else :color="statusClass(row.value)">{{ row.value || '-' }}</CBadge>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: {
        'row': [Object],
    },
    methods: {
        ...mapGetters('CommonData', ['getStatusColors']),
        statusColor(status) {
            if (Object.hasOwn(this.getStatusColors(), status)) {
                return this.getStatusColors()[status];
            } else {
                return false;
            }
        },
        statusClass(status) {
            if (status == 'kva') {
                return 'warning';
            } else if (status == 'production') {
                return 'info'
            } else if (status == 'paid') {
                return 'success'
            } else if (status == 'done') {
                return 'primary'
            } else if (status == 'cancel') {
                return 'danger'
            } else {
                return 'light';
            }
        },
    }
}
</script>